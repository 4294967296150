import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { ServiceWorkerModule } from '@angular/service-worker';

import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
    EntityCollectionReducerMethodsFactory,
    EntityDataModule,
} from '@ngrx/data';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { RouterStateModule } from '@digital-cap-fe/state/router-state';
import { AuthModule } from '@digital-cap-fe/platform/auth';
import { ApiMessagesModule } from '@digital-cap-fe/state/api-messages';
import { MonitoringModule } from '@digital-cap-fe/platform/monitoring';
import { ModalModule } from '@digital-cap-fe/widgets/modal';
import { getVersionInfo } from '@digital-cap-fe/utilities';
import { ENVIRONMENT_TOKEN } from '@digital-cap-fe/models';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { LogoComponent } from './components/logo/logo.component';
import { InitComponent } from './containers/init/init.component';
import { UpdateCheckerService } from './services/update-checker/update-checker.service';
import { getLocaleID } from '../initialize-locale';
import { AdditionalEntityCollectionReducerMethodsFactory } from './redux-data-reducer.factory';
import {BASE_PATH} from "@digital-cap-fe/openapi";
import { Smartseal3dEffects } from 'libs/state/smartseal3d-graph-state/src/lib/state/smartseal3d-graph.effects';
import { reducer, smartseal3dGraphFeatureKey } from 'libs/state/smartseal3d-graph-state/src/lib/state/smartseal3d-graph.reducer';

@NgModule({
    declarations: [AppComponent, LogoComponent, InitComponent],
    imports: [
        MonitoringModule.startMonitoring({
            enabled: environment.production,
            logRocketAppId: '7ka4x7/digitalcapfe',
            release: () => {
                const { gitShortSHA, tagName } = getVersionInfo();
                return tagName || gitShortSHA;
            },
        }),
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        AppRoutingModule,
        MonacoEditorModule,
        HttpClientModule,
        FontAwesomeModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        RouterModule,
        StoreModule.forRoot(
            {},
            {
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                    strictActionSerializability: false,
                    strictStateSerializability: true,
                    strictActionTypeUniqueness: true,
                },
            },
        ),
        StoreDevtoolsModule.instrument({
            logOnly: environment.production,
            name: 'DigitalCap',
        }),
        EffectsModule.forRoot([]),
        EntityDataModule.forRoot({}),
        AuthModule,
        RouterStateModule,
        // ApiMessagesModule,
        ModalModule.withChildRoutes({
            path: 'modal',
            outlet: 'modal-outlet',
            children: [
                {
                    path: 'assign-user-to-address',
                    loadChildren: () =>
                        import(
                            '@digital-cap-fe/features/address-user-assignment'
                        ).then((module) => module.AddressUserAssignmentModule),
                },
                {
                    path: 'assign-user-to-device',
                    loadChildren: () =>
                        import(
                            '@digital-cap-fe/features/device-user-assignment'
                        ).then((module) => module.DeviceUserAssignmentModule),
                },
                {
                    path: 'device-sabotage-decision',
                    loadChildren: () =>
                        import(
                            '@digital-cap-fe/features/device-sabotage-decision'
                        ).then((module) => module.DeviceSabotageDecisionModule),
                },
                {
                    path: 'dependency-management',
                    loadChildren: () =>
                        import(
                            '@digital-cap-fe/features/device-hardware-dependency-management'
                        ).then(
                            (module) =>
                                module.DeviceHardwareDependencyManagementModule,
                        ),
                },
                {
                    path: 'device-pressure-graph',
                    loadChildren: () =>
                        import(
                            '@digital-cap-fe/features/device-pressure-graph'
                        ).then((module) => module.DevicePressureGraphModule),
                },
                {
                    path: 'device-information',
                    loadChildren: () =>
                        import(
                            '@digital-cap-fe/features/device-information'
                        ).then((module) => module.DeviceInformationModule),
                },
                {
                    path: 'device-photo-viewer',
                    loadChildren: () =>
                        import(
                            '@digital-cap-fe/features/device-photo-viewer'
                        ).then((module) => module.DevicePhotoViewerModule),
                },
                {
                    path: 'imp-cap-device-photo-viewer',
                    loadChildren: () =>
                        import(
                            '@digital-cap-fe/features/imp-cap-device-photo-viewer'
                        ).then(
                            (module) => module.ImpCapDevicePhotoViewerModule,
                        ),
                },
                {
                    path: 'imp-cap-consumption-data',
                    loadChildren: () =>
                        import(
                            '@digital-cap-fe/features/imp-cap-consumption-data'
                        ).then((module) => module.ImpCapConsumptionDataModule),
                },
                {
                    path: 'turn-off-device',
                    loadChildren: () =>
                        import('@digital-cap-fe/features/device-turn-off').then(
                            (module) => module.DeviceTurnOffModule,
                        ),
                },
                {
                    path: 'device-3d-graph',
                    loadChildren: () =>
                        import('@digital-cap-fe/features/device-3d-graph').then(
                            (module) => module.Deviced3dGraphModule,
                        ),
                },
                {
                    path: 'energy-tracker-consumption-data',
                    loadChildren: () =>
                        import(
                            '@digital-cap-fe/features/energy-tracker-consumption-data'
                        ).then((module) => module.FeaturesEnergyTrackerConsumptionDataModule),
                },
                {
                    path: 'energy-tracker-device-info',
                    loadChildren: () =>
                        import(
                            '@digital-cap-fe/features/energy-tracker-device-info'
                        ).then((module) => module.FeaturesEnergyTrackerDeviceInfoModule),
                },
                {
                    path: 'pressure-cap-pressure-graph',
                    loadChildren: () =>
                        import(
                            '@digital-cap-fe/features/pressure-cap-pressure-graph'
                        ).then((module) => module.PressureCapPressureGraphModule),
                },
                {
                    path: 'pressure-cap-temperature-graph',
                    loadChildren: () =>
                        import(
                            '@digital-cap-fe/features/pressure-cap-temperature-graph'
                        ).then((module) => module.PressureCapTemperatureGraphModule),
                }
            ],
        }),
    ],
    providers: [
        UpdateCheckerService,
        {
            provide: LOCALE_ID,
            useFactory: getLocaleID,
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 4000,
                horizontalPosition: 'start',
                verticalPosition: 'top',
            },
        },
        {
            provide: ENVIRONMENT_TOKEN,
            useValue: environment,
        },
        {
            provide: BASE_PATH,
            useValue: ' '
        },
        {
            provide: EntityCollectionReducerMethodsFactory,
            useClass: AdditionalEntityCollectionReducerMethodsFactory,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
